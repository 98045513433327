import { useFormik } from "formik";
import React, { useEffect, useReducer, useState } from "react";
import { IFormElement } from "~interfaces/components";
import { fetchFields, submitForm } from "~services/formstack-service";
import { handleErrors } from "~services/service-helpers";
import FormElements from "~components/web-ui/form/form-elements";
import {
  formSubmissionReducer,
  ISubmissionAction,
  ISubmissionState,
} from "~components/web-ui/form/state/form-state";
import {
  convertDataForSubmission,
  createFormikInitialValues,
  fieldValidation,
  createFormPages,
} from "~components/web-ui/form/utils/form-helper";
import { renderSubmitButton } from "~components/web-ui/form/utils/form-render-helpers";
import Card from "~components/web-ui/card";
import { css } from "@emotion/core";

interface IFormProps {
  terms_and_conditions?: string;
  submit_button_text?: string;
  spacing_between_elements?: string;
  enable_labels?: boolean;
  form_id?: string;
  form_auth?: string;
  edit_submission?: "success" | "error";
  hf?: any;
}
//
export const HfForm = (props: IFormProps) => {
  const { submit_button_text, enable_labels, form_id = "", hf } = props;

  const [formFields, setFormFields] = useState<Array<IFormElement>>([]);
  const [formikInitialValues, setFormikInitialValues] = useState<
    Record<string, string>
  >({});

  const initialModalState: ISubmissionState = {
    modalIsOpen: false,
    status: "NOT_SUBMITTED",
  };
  const [modalState, modelStateDispatch] = useReducer(
    formSubmissionReducer,
    initialModalState
  );

  const [formPage, setFormPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  /**
   * Submits the form and shows a modal with success or error screen
   */
  const onSubmit = async (
    values: Record<string, string>,
    formId: string,
    dispatch: React.Dispatch<ISubmissionAction>
  ) => {
    let formattedValues = convertDataForSubmission(values);
    formattedValues = Object.fromEntries(
      Object.entries(formattedValues).map(([key, value]) => {
        if (value === "affiliateId") {
          return [key, hf?.hfId ?? "0"];
        }
        if (value === "hfState") {
          return [key, hf?.state ?? ""];
        }
        if (value === "hfStateabbv") {
          return [key, hf?.stateAbbv ?? ""];
        }
        if (value === "hfEmail") {
          return [key, hf?.email ?? ""];
        }
        if (value === "hfName") {
          return [key, hf?.fullName ?? ""];
        }
        return [key, value];
      })
    );

    dispatch({ type: "SUBMITTING" });
    try {
      const response = await submitForm(formattedValues, formId);
      handleErrors(response);
      dispatch({ type: "OPEN_SUCCESS" });
      dataLayer.push({ event: "formSubmissionSuccess" });
    } catch (error) {
      dispatch({ type: "OPEN_ERROR" });
      // tslint:disable-next-line:no-console
      console.log("Error submitting the form", error);
    }
  };

  const onSubmitWithFormId = (formId: string) => (values: any) => (
    dispatch: React.Dispatch<ISubmissionAction>
  ) => onSubmit(values, formId, dispatch);

  const validateWithFormfields = (formFields: Array<IFormElement>) => {
    return (values: Record<string, string>) =>
      fieldValidation(values, formFields);
  };

  const renderField = (field: IFormElement) => {
    if (field.type === "section") {
      return <h4 key={field.id}>{field.section_heading}</h4>;
    }
    return (
      <FormElements
        formik={formik}
        key={field.id}
        className="u-mb-s"
        enableLabels={enable_labels}
        {...field}
      />
    );
  };

  const renderPage: any = (
    fields: Array<IFormElement | Array<IFormElement>>,
    currentPage: number
  ) => {
    if (Array.isArray(fields[currentPage])) {
      return renderPage(fields[currentPage]);
    }

    return fields.map((field) => renderField(field as IFormElement));
  };

  useEffect(() => {
    async function fetchFormFields() {
      try {
        const response = await fetchFields(form_id);
        let fieldsData = response as Array<IFormElement>;
        fieldsData = createFormPages(fieldsData);
        setFormFields(fieldsData);
        setNumberOfPages(
          fieldsData.reduce(
            (acc, currValue) => (Array.isArray(currValue) ? acc + 1 : acc),
            0
          )
        );
        // Create the initial value object for formik
        const initialFormObject = createFormikInitialValues(fieldsData);
        setFormikInitialValues(initialFormObject);
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log(error);
      }
    }

    fetchFormFields();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: (values, bag) => {
      if (numberOfPages > 0 && numberOfPages !== formPage + 1) {
        bag.setTouched({});
        bag.setSubmitting(false);
        setFormPage(formPage + 1);
      } else {
        onSubmitWithFormId(form_id)(values)(modelStateDispatch);
      }
    },
    validate:
      numberOfPages > 0
        ? validateWithFormfields(
            (formFields[formPage] as never) as Array<IFormElement>
          )
        : validateWithFormfields(formFields),
    // validateOnBlur: true,
    // validateOnChange: false,
  });

  return (
    <>
      <form
        className={`ef-form ${
          modalState.status === "ERROR" || modalState.status === "SUCCESS"
            ? "u-hide-s-up"
            : ""
        }`}
        onSubmit={formik.handleSubmit}
      >
        <h4 className="u-mb-xs u-text-center">Find your exchange student</h4>
        <p style={{ maxWidth: "100%" }} className="u-text-center">
          Sign up to get started and find out more about hosting
        </p>
        {Object.keys(formik.values).length && renderPage(formFields, formPage)}
        <div className="ef-row">
          <div className="ef-col -s-12 -m-6 -m-offset-3 form-style-center">
            {renderSubmitButton(
              numberOfPages,
              formPage,
              modalState,
              submit_button_text
            )}
          </div>
        </div>
      </form>
      {modalState.status === "ERROR" ? (
        <p>There was a problem somewhere. Please try again later.</p>
      ) : null}
      {modalState.status === "SUCCESS" ? (
        <div className="ef-row">
          <div className="ef-col -s-12 -m-8 -m-offset-2 u-text-center">
            <h3>Thank you!</h3>
            <p>
              We will reach out to you shortly to chat more about this exciting
              volunteer program and to answer all your questions.
            </p>
          </div>
          <div
            className="ef-col -s-12 -m-6 u-pt-xxl"
            css={css`
              h4 {
                font-size: 20px;
                @media (max-width: 420px) {
                  font-size: 14px;
                }
              }
            `}
          >
            <Card
              component="advanced-teaser-card"
              image={{
                focusPoint: { x: 0, y: 0 },
                image:
                  "//a.storyblok.com/f/69908/600x400/26a1eb320a/fyi-card.png",
                imageSize: { width: 600, height: 400 },
              }}
              aspect_ratio="3:2"
              aspect_ratio_mobile="4:3"
              gradient="-gradient-none"
              link={{
                cached_url: "/find-your-student",
                fieldtype: "multilink",
                id: "",
                linktype: "url",
                url: "/find-your-student",
              }}
              open_in_a_new_tab={true}
              title={"Find your student"}
              description={"Review profiles"}
            />
          </div>
          <div
            className="ef-col -s-12 -m-6 ef-col -s-12 -m-6 u-pt-xxl"
            css={css`
              h4 {
                font-size: 20px;
              }
            `}
          >
            <Card
              component="advanced-teaser-card"
              image={{
                focusPoint: { x: 0, y: 0 },
                image:
                  "//a.storyblok.com/f/69908/600x400/52e35b3bdc/guide-card.png",
                imageSize: { width: 600, height: 400 },
              }}
              aspect_ratio="3:2"
              aspect_ratio_mobile="4:3"
              gradient="-gradient-none"
              text_color="fff"
              link={{
                cached_url: "/Guide",
                fieldtype: "multilink",
                id: "",
                linktype: "url",
                url: "/Guide",
              }}
              open_in_a_new_tab={true}
              title={"Our guide to hosting"}
              description={"Learn more"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
