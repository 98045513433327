import React from "react";
import IconLocationComponent from "@ef-global/web-ui-react/lib/icons/IconLocation";
import Button from "~components/web-ui-react/button";
import { HfProfilePhoto } from "~components/custom/hf/hf-profile-photo";

interface HfProps {
  fullName: string;
  zipCode: string;
  state: string;
  stateAbbv: string;
  city: string;
  email: string;
  hfId: number;
  affiliatePageUrl: string;
  photoId: number;
}

export const HfData = ({ hf }: { hf: HfProps }) => {
  return (
    <>
      <section className="ef-section u-pb-m u-pt-m">
        <div className="ef-container">
          <div className="ef-row">
            <div className="ef-col">
              <p>
                <strong>{hf.fullName}</strong>
                <br />
                <span
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Host Family
                </span>{" "}
                <br />
                EF High School Exchange Year
                <br />
                <IconLocationComponent
                  className="ef-icon -arrow-left"
                  style={{
                    display: "inline",
                    marginRight: "6px",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <span
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {hf.stateAbbv}
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="ef-section u-pb-m u-pt-m">
        <div className="ef-container">
          <div className="ef-row">
            <div className="ef-col -m-8 -s-12">
              <h4 className="u-s-mb-xs u-m-mb-s u-show-s-only">
                Hi! I think you would be a great host family.
              </h4>
              <h2 className="u-show-m-up">
                Hi! I think you would be a great host family.
              </h2>
            </div>
            <div className="ef-col -m-6 -s-12">
              <p className="ef-h5 u-f-book">
                Hosting an exchange student is a great way to bring more fun
                and excitement into your home – while also learning about
                another part of the world!
              </p>
            </div>
            <div className="ef-col -s-12 u-mt-l">
              <Button
                text="Learn more"
                primary
                scroll_to="contact-us"
                id="host-cta-primary"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
